import { Suspense } from 'react';
import { Typography, Carousel, Button, TypographyVariants } from '../ui';
import { Await } from '@remix-run/react';
import { GenericProductsCollection, ProductModel } from '~/models';
import { ProductItem } from '.';
import { useMediaQuery } from 'react-responsive';
import { VariantProps } from 'class-variance-authority';

interface Props
{
  products: Promise<GenericProductsCollection> | GenericProductsCollection | Array<ProductModel>;
  title: string;
  description?: string;
  route?: string;
  titleClassName?: string;
  variant?: VariantProps<typeof TypographyVariants>['variant'];
  id?: string;
  reloadDocument?: boolean;
}

export const ProductDeals = ({ products, title, description, route, variant, id, titleClassName, reloadDocument }: Props) =>
{
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <>
      <div className="mt-14" id={ id }>
        <Typography variant={ variant ?? 'headline3' } className={ `${ (route ? "mb-1" : "mb-5") } ${ titleClassName }` } type='h2'>{ title }</Typography>
        { description ? <Typography variant="body1" dangerouslySetInnerHTML={ { __html: description } } className="my-4" /> : '' }
        { route && <div className="flex justify-end mb-5">
          <Button
            size="sm"
            className="px-10 md:px-6 !no-underline text-white"
            variant="cool"
            to={ route }
          >
            <Typography variant="button">View All</Typography>
          </Button>
        </div> }
      </div>
      { (products instanceof Promise) ? <Suspense fallback={ <div>Loading...</div> }>
        <Await resolve={ products }>
          { ({ collection }) =>
          {
            return (
              <ProductsCarousel collection={ collection } isMobile={ isMobile } reloadDocument={ reloadDocument } />
            );
          } }
        </Await>
      </Suspense> :
        Array.isArray(products) ?
          <ProductsCarousel collection={ products } isMobile reloadDocument={ reloadDocument } /> :
          <ProductsCarousel collection={ (products as GenericProductsCollection).collection } isMobile reloadDocument={ reloadDocument } /> }
    </>
  );
};

const ProductsCarousel = (
  {
    collection,
    isMobile,
    reloadDocument
  }:
    {
      isMobile: boolean,
      reloadDocument: boolean | undefined,
      collection: Array<ProductModel>
    }) => <Carousel
      spaceBetween={ 15 }
      loop={ isMobile }
      swiperSlideClassName='max-w-fit pt-4 pb-8 pl-2'
    >
    { collection.map((product) => (
      <ProductItem
        key={ product.id }
        product={ product }
        rate={ 4.5 }
        quickAdd
        isNew
        discount={ 15 }
        reloadDocument={ reloadDocument }
      />
    )) }
  </Carousel>
